import {firebaseApp} from '../configureFirebase';


import { getDatabase, ref,set ,push} from "firebase/database";

import { updateObject } from '../../shared/utility';
import  createDataContext  from '../createDataContext';
import * as actionTypes from '../actionTypes'; 

const app=firebaseApp;

  
  export const initialState = {
      registrations: [],
      message:'',
      loading: false,
  };

   const registrationReducer = (state = initialState, action) => {
  
      switch (action.type)
      {
  
          case actionTypes.ADD_REGISTRATION_START:
              return updateObject(state, { loading: true });
  
          case actionTypes.ADD_REGISTRATION_SUCCESS:
              const newItem = updateObject(action.registrationData,
                  { id: action.registrationId });
  
              return updateObject(state, {
                  loading: false,
                  message:'success',
                  registrations: state.registrations.concat(newItem)
              }
              );
          case actionTypes.CLEAR_MESSAGE:
                return updateObject(state, { message: '' });

          case actionTypes.ADD_REGISTRATION_FAIL:
              return updateObject(state, { loading: false, error: action.error ,message:'failure'});
  
          case actionTypes.FETCH_REGISTRATIONS_START:
              return updateObject(state, { loading: true });
  
          case actionTypes.FETCH_REGISTRATIONS_SUCCESS:
              return updateObject(state, { registrations: action.registrations, loading: false });
  
  
          case actionTypes.FETCH_REGISTRATIONS_FAIL:
              return updateObject(state, { loading: false });
  
          default:
              return state;
      }
  };
  

   const addRegistrationSuccess = (id) => {
    return {
        type: actionTypes.ADD_REGISTRATION_SUCCESS,
        registrationId: id,
    };
};



 const clearMessage = () => { 
    return {
        type: actionTypes.CLEAR_MESSAGE,
        message: ''
    };
};

 const addRegistration = dispatch => ({email}) => {

        const db = getDatabase();
        const registrationsRef = ref(db, 'registrations');
        const newRegRef = push(registrationsRef);
        set(newRegRef, {
                 email: email,
                     });
        dispatch(addRegistrationSuccess(email))
     setTimeout(() => { dispatch(clearMessage()) }, 10000);
    };




 const fetchRegistrationsSuccess = regs => {
    return {
        type: actionTypes.FETCH_REGISTRATIONS_SUCCESS,
        registrations: regs
    };
};

 const fetchRegistrationsFail = error => {
    return {
        type: actionTypes.FETCH_REGISTRATIONS_FAIL,
        error: error
    };
};


 const fetchRegistrations = dispatch => () => {
        const db = getDatabase();
        const registrationsRef = ref(db, 'registrations');

   
        registrationsRef.on('value', function (snapshot) {
                const res = snapshot.val();
                var registrations = [];
                if (res)
                {
                  var keys = Object.keys(registrations);
                  keys.forEach(function (key) {
                    registrations.push(snapshot.val()[key]);
                  });
                  registrations.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
                }
                fetchRegistrationsSuccess(registrations);
              });
          };
        

          export const { Provider, Context} = createDataContext(
            registrationReducer,
            {
                addRegistration,
                fetchRegistrations,
                clearMessage
            },
            initialState
          );
          