
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const ADD_REGISTRATION_START = 'ADD_REGISTRATION_START';
export const ADD_REGISTRATION_SUCCESS = 'ADD_REGISTRATION_SUCCESS';
export const ADD_REGISTRATION_FAIL = 'ADD_REGISTRATION_FAIL';
export const ADD_REGISTRATION_INIT = 'ADD_REGISTRATION_INIT';

export const FETCH_REGISTRATIONS_START = 'FETCH_REGISTRATIONS_START';
export const FETCH_REGISTRATIONS_SUCCESS = 'FETCH_REGISTRATIONS_SUCCESS';
export const FETCH_REGISTRATIONS_FAIL = 'FETCH_REGISTRATIONS_FAIL';